<template>
	<div class="allstore">
		<div v-for="(item, index) in storelist" :key="index">
			<shops-list :shopItem="item"></shops-list>
		</div>
	</div>
</template>
<script>
	import {
		getGoodsLists
	} from "@/http/api";
	import ShopsList from "@/components/shopsInfo/ShopsList";
	import {
		getLocationInfo
	} from "@/common/wxJssdk";
	export default {
		components: {
			ShopsList
		},
		data() {
			return {
				storelist: [],
				goods_id: 0,
				location: {}
			};
		},
		mounted() {
			if (this.$route.query.goods_id) {
				this.goods_id = this.$route.query.goods_id
				
				let that = this;
				getLocationInfo("gcj02", function(locationData) {
					console.log(locationData)
					let curSiteInfo = that.$LStorage.getItem('curSiteInfo');
					if (locationData) {
						that.location = {
							location_lng: locationData.longitude,
							location_lat: locationData.latitude,
						}
					} else {
						that.location = {
							location_lng: curSiteInfo.location_lng,
							location_lat: curSiteInfo.location_lat,
						}
					}
					that.StoreList();
				});
			} else {
                this.$dialog.alert({
                    message: '商品门店页面参数异常, 点击跳转到上一页看看',
                }).then(() => {
                    this.$router.go(-1);
                });
            }
		},
		methods: {
 
			//店铺列表
			async StoreList() {
				const res = await getGoodsLists({
					data: {
						goods_id: this.goods_id,
						location_lng: this.location.location_lng,
						location_lat: this.location.location_lat,
					}
				});
				console.log(res);
				this.storelist = res.data;
			}
		},
		created() {
			//document.title = "全部门店";
		}
	};
</script>
<style lang="scss">
	.allstore {
		width: 100%;

		.details-adress {
			padding: 0.2rem 0.26rem 0.34rem 0.23rem;
			box-sizing: border-box;
			background: #fff;

			.details-adress-top {
				display: flex;
				position: relative;

				.adress-top-left {
					img {
						width: 1.34rem;
						height: 1.34rem;
						display: block;
						border-radius: 0.07rem;
					}
				}

				.adress-top-center {
					margin-left: 0.27rem;

					p:nth-child(1) {
						font-size: 0.3rem;
						margin-bottom: 0.36rem;
						margin-top: 0.06rem;
					}

					.routerto {
						display: block;
						width: 1.43rem;
						height: 0.51rem;
						border: 1px solid #e40011;
						border-radius: 0.07rem;
						color: #e40011;
						text-align: center;
						line-height: 0.4rem;

						p {
							height: 0.15rem;
						}
					}
				}

				.adress-top-right {
					position: absolute;
					bottom: 0.2rem;
					right: 0;
					width: 1.48rem;
					height: 0.63rem;
					padding-left: 0.3rem;
					//    padding-top: 0.13rem;
					//    padding-bottom: 0.13rem;
					box-sizing: border-box;
					border-left: 2px dashed #c3c3c3;

					.rigth-home {
						display: flex;

						img {
							width: 0.36rem;
							height: 0.43rem;
							display: block;
						}

						div:nth-child(1) {
							padding-right: 0.14rem;
							padding-top: 0.1rem;
							box-sizing: border-box;
						}

						div:nth-child(2) {
							width: 0.66rem;
							font-size: 0.18rem;
							color: #fc6f15;
						}
					}
				}
			}

			.details-adress-bottom {
				margin-top: 0.21rem;
				display: flex;

				div {
					img {
						width: 0.3rem;
						height: 0.36rem;
						display: block;
					}
				}

				span {
					font-size: 0.24rem;
					color: #e40011;
					display: block;
					margin-left: 0.12rem;
					padding-top: 0.08rem;
					box-sizing: border-box;
				}

				p {
					margin-left: 0.12rem;
					font-size: 0.24rem;
					color: #606060;
					padding-top: 0.08rem;
					box-sizing: border-box;
				}
			}
		}
	}
</style>
